<template>
    <div class="row text-center">
        <div class="col-12 mt-2 mb-3">
            <BButton
                class="mr-3"
                data-cy="btnSearch"
                size="lg"
                variant="primary"
                :disabled="loading"
                @click="submitSearch"
            >
                Search
            </BButton>

            <BButton
                :disabled="loading"
                data-cy="btnClear"
                size="lg"
                variant="secondary"
                @click="clear"
            >
                Clear
            </BButton>
        </div>

        <div class="col-12">
            <p
                v-if="!submitted"
                data-cy="submissionNote"
                class="text-emphasize"
            >
                All results will be returned when no fields are selected.
            </p>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import searchMixin from '@/mixins/search';

    export default {
        name: 'STSearchButtons',
        mixins: [searchMixin],
        computed: {
            ...mapState([
                'submitted',
                'loading',
            ]),
        }
    };
</script>
