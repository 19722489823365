<template>
    <div class="col-12 col-md-8 col-lg-6 mb-4">
        <div class="d-inline-block">
            <label data-cy="searchIdInputLabel">
                {{ capitalType }} ID
            </label>

            <FontAwesomeIcon
                :id="`id${capitalType}HelpWording`"
                class="kc-help-wording ml-2"
                icon="info-circle"
            />
        </div>

        <BInputGroup>
            <BFormInput
                :id="`${type}Id`"
                v-model="searchId"
                :data-cy="`${type}IdInput`"
                :disabled="disabled"
                :placeholder="`input a ${type} id...`"
                @input="$emit('input', $event);"
            />

            <BInputGroupText
                slot="append"
                role="button"
                @click="clear"
            >
                <FontAwesomeIcon
                    :icon="['far', 'times']"
                    class="m-0"
                />
            </BInputGroupText>
        </BInputGroup>

        <BPopover
            custom-class="kc-help-wording"
            placement="right"
            :target="`id${capitalType}HelpWording`"
            title="ID Search"
            triggers="hover"
        >
            If a value for the id is provided, all other search fields will be ignored
        </BPopover>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {faTimes} from '@fortawesome/pro-regular-svg-icons';
    // eslint-disable-next-line you-dont-need-lodash-underscore/capitalize
    import capitalize from 'lodash/capitalize';

    import searchMixin from '@/mixins/search';

    library.add(faTimes);

    export default {
        name: 'STSearchIdInput',
        mixins: [searchMixin],
        model: {
            prop: 'value',
        },
        props: {
            disabled: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                previousSearchId: '',
                searchId: this.value,
            };
        },
        computed: {
            capitalType() {
                return capitalize(this.type);
            }
        },
        watch: {
            searchId(value) {
                if (value === '' && this.previousSearchId !== '') {
                    this.clear();
                }

                this.previousSearchId = value;
            }
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    .help-wording
        font-size: 13px
</style>
