<template>
    <div class="col-12 col-md-8 col-lg-6 mb-4">
        <div>
            <label data-cy="keywordSearchInputLabel">
                Keyword
            </label>
            <BInputGroup>
                <BFormInput
                    id="keyword"
                    v-model="keyword"
                    data-cy="keywordInput"
                    :disabled="disabled"
                    placeholder="enter a keyword to search for..."
                    @input="$emit('input', $event)"
                />

                <BInputGroupText
                    slot="append"
                    role="button"
                    @click="keyword=''; $emit('input', '')"
                >
                    <FontAwesomeIcon
                        :icon="['far', 'times']"
                        class="m-0"
                    />
                </BInputGroupText>
            </BInputGroup>
        </div>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {faTimes} from '@fortawesome/pro-regular-svg-icons';

    import searchMixin from '@/mixins/search';

    library.add(faTimes);

    export default {
        name: 'STKeywordInput',
        mixins: [searchMixin],
        model: {
            prop: 'value',
        },
        props: {
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data() {
            return {
                keyword: this.value,
            };
        },
    };
</script>
