import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
import snakeCase from 'lodash/snakeCase';
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
    mixins: [toastsMixin],
    computed: {
        ...mapState(['searchFields']),
    },
    methods: {
        buildSearchUrl() {
            let page = this.$route.query.page ? this.$route.query.page : this.searchFields.page,
                size = this.$route.query.perPage ? this.$route.query.perPage : this.searchFields.perPage,
                baseURL = `/${this.$route.name}s/?page[size]=${size}&page=${page}`,
                keys = Object.keys(this.$route.query);

            keys.forEach((key) => {
                if (this.$route.query[key]) {
                    if (key === 'startDate') {
                        baseURL += `&filter[date.gte]=${this.$route.query[key]}T00:00:00`;
                    } else if (key === 'endDate') {
                        baseURL += `&filter[date.lte]=${this.$route.query[key]}T23:59:59`;
                    } else if (key === 'requestStart') {
                        baseURL += `&filter[request_started_at.gte]=${this.$route.query[key]}T00:00:00`;
                    } else if (key === 'requestEnd') {
                        baseURL += `&filter[request_ended_at.lte]=${this.$route.query[key]}T23:59:59`;
                    } else if (key === 'requestMethod') {
                        baseURL += `&filter[request_method]=${this.$route.query[key]}`;
                    } else if (key === 'search') {
                        baseURL += `&search=${this.$route.query[key]}`;
                    } else if (!(key in this.searchFields)) {
                        baseURL += `&filter[${snakeCase(key)}.iexact]=${this.$route.query[key]}`;
                    }
                }
            });

            return baseURL;
        },
        async clear() {
            this.$ga.event('Search', 'Search Cleared', 'action');
            this.SET_DATA({type: 'submitted', data: false});
            this.SET_DATA({type: 'initialSearchSubmitted', data: false});

            if (this.$route.fullPath.includes('?')) {
                this.$emit('resetFields');
            }

            this.submitSearch();
        },
        disabledBySearchId(searchId) {
            return this.loading || searchId !== '';
        },
        async resetSharedValues() {
            this.CLEAR_SEARCH_FIELDS();

            this.SET_SEARCH_FIELDS({
                key: 'page',
                value: this.$route.query.page ? this.$route.query.page : 1
            });

            this.SET_SEARCH_FIELDS({
                key: 'perPage',
                value: this.$route.query.page ? this.$route.query.perPage : 25
            });

            if (this.dateRange.length) {
                this.$refs.datePicker.clear();
            }

            this.$refs.searchIdInput.searchId = '';
            this.$refs.STKeywordInput.keyword = '';

            if (this.$route.fullPath.includes('?')) {
                await this.$router.push({query: {}});
            }

            await this.setupDropdowns();
        },
        async setupDropdowns() {
            try {
                this.SET_DATA({type: 'loading', data: true});

                this.dropdownOptions = await this.fetchDistinct({
                    company: this.$route.query.company,
                    vendor: this.$route.query.vendor,
                    source: this.$route.query.source,
                    service: this.$route.query.service,
                    requestMethod: this.$route.query.requestMethod,
                    policySystem: this.$route.query.policySystem,
                });

                this.SET_DATA({type: 'loading', data: false});
            } catch (e) {
                this.error('The records failed to be retrieved.', 'Records Not Found');
            }
        },
        async submitSearch(newSearch = true) {
            this.SET_DATA({type: 'submitted', data: true});
            this.SET_DATA({type: 'loading', data: true});

            if (newSearch) {
                this.SET_DATA({type: 'initialSearchSubmitted', data: true});
            }

            try {
                if (this.$route.query.searchId) {
                    await this.fetchRecordById({
                        id: this.$route.query.searchId,
                        isService: this.$route.name.includes('service'),
                        fromSearch: true
                    });

                    this.SET_DATA({type: 'loading', data: false});

                    return;
                } else {
                    let baseURL = this.buildSearchUrl();

                    await this.fetchSearchResults(baseURL);
                }
            } catch (e) {
                this.SET_DATA({type: 'results', data: []});
                this.SET_DATA({type: 'count', data: 0});
                this.error('The records could not be found.', 'Records Not Found');
            }

            this.SET_DATA({type: 'loading', data: false});
        },
        uniq(stuff) {
            return [...new Set(stuff)];
        },
        ...mapActions([
            'fetchDistinct',
            'fetchRecordById',
            'fetchSearchResults',
        ]),
        ...mapMutations(['CLEAR_SEARCH_FIELDS', 'SET_DATA', 'SET_SEARCH_FIELDS'])
    },
};
